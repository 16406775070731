import React, { useEffect, useRef, useState } from 'react';
import {
  CIMPRESS_INTERNAL,
  IS_CUSTOMER,
  useIdentity,
  useWebAuth,
} from '@vp/digital-auth-lib';
import Loading from '../components/common/Loading';
import { getLogger } from '@vp/digital-logging-lib';

interface IdentityContext {
  canonicalUser?: CanonicalUser;
  redirected: boolean;
  setRedirected: (newSate: boolean) => void;
}

const IS_INTERNAL_CLAIM = 'https://claims.cimpress.io/cimpress_internal';

const IdentityContext = React.createContext<IdentityContext>(
  {} as IdentityContext
);

type IdentityProviderProps = {
  children: React.ReactNode;
  requireAuth?: boolean;
  isInternalUserView?: boolean;
};

const IdentityProvider: React.FC = (
  { children, isInternalUserView }: IdentityProviderProps,
  requireAuth = true
) => {
  const [canonicalUser, setUserIdentity] = useState<CanonicalUser | undefined>(
    undefined
  );
  const [redirected, setRedirected] = useState<boolean>(false);
  const webAuth = useWebAuth();
  const identity = useIdentity();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { current: logger } = useRef(getLogger({ page: 'IdentityContext' }));

  useEffect(() => {
    if (!requireAuth) {
      setIsAuthorized(true);
      return;
    }
    if (!webAuth) {
      return;
    }
    if (!identity.isSignedIn) {
      webAuth?.signIn();
    } else if (
      identity.profile &&
      ((identity.profile[IS_CUSTOMER] && isInternalUserView) ||
        (identity.profile[CIMPRESS_INTERNAL] && !isInternalUserView))
    ) {
      logger.warn(
        `User Is Signed in using a different Client. Trying to sign in again`
      );
      webAuth.signOut();
      webAuth.signIn();
    } else {
      setIsAuthorized(true);
    }

    if (webAuth && identity) {
      const userIdentity = {
        isSignedIn: identity.isSignedIn,
        canonicalId: identity.canonicalId,
        isInternal: identity.profile[IS_INTERNAL_CLAIM] || false,
        authToken: identity.accessToken,
        anonymousId: identity.recentAnonymousIds
          ? identity.recentAnonymousIds[0]
          : null,
      };
      setUserIdentity(userIdentity);
    } else {
      logger.error('An error occurred while initializing Auth.');
    }
  }, [webAuth, identity]);
  if (!isAuthorized) {
    return <Loading />;
  }
  return (
    <IdentityContext.Provider
      value={{ canonicalUser, redirected, setRedirected }}
    >
      {children}
    </IdentityContext.Provider>
  );
};

export { IdentityContext, IdentityProvider };
